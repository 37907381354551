import { FormattedMessage, IntlShape, useIntl } from "react-intl";

import { DashboardProductTag } from "../../data-store";
import {
  impactCategoryToEffectType,
  impactCategoryToEffectTypePerKg,
} from "../../domain/EffectType";
import { ImpactCategory } from "../../domain/impactCategories";
import {
  getImpactCategoryRating,
  ImpactRating,
} from "../../domain/impactRatings";
import BarChart2 from "../graphs/BarChart2";
import Label from "../labels/Label";
import { averageIntensity } from "./helperFunctions";
import "./AverageImpactIntensityByTag.css";

interface AverageImpactIntensityByTagProps {
  impactCategory: ImpactCategory;
  tags: Array<DashboardProductTag>;
}

export function AverageImpactIntensityByTag(
  props: AverageImpactIntensityByTagProps
) {
  const { tags, impactCategory } = props;

  const intl = useIntl();

  const bars = tags.map((tag) => ({
    label: tag.name,
    value: [averageIntensity(tag, impactCategory)],
    count: tag.count,
    impactRating: getImpactCategoryRating(tag, impactCategory),
  }));

  const effectType = impactCategoryToEffectType(impactCategory);
  const dependentAxisLabel = `${effectType.title(
    intl
  )} ${effectType.largeUnitString(intl)}`;

  return (
    <div className="AverageImpactIntensityByTag">
      <h4 className="m-0">
        <FormattedMessage
          id="components/AverageImpactIntensityByTag/title"
          defaultMessage="Average Impact Intensity by Tag"
        />
      </h4>
      <BarChart2
        bars={bars}
        dependentAxisLabel={dependentAxisLabel}
        height={250}
        sorted
        maxBars={32}
        hideFinalBar
        maintainAspectRatio={false}
        customTooltip={(tooltip: { dataPoints: Array<DataPoint> }) => (
          <Tooltip
            dataPoints={tooltip.dataPoints}
            impactCategory={impactCategory}
            intl={intl}
          />
        )}
      />
    </div>
  );
}

interface DataPoint {
  raw: number;
  label: string;
  dataIndex: number;
  dataset: {
    formattedBars: Array<{
      count: number;
      impactRating: ImpactRating;
    }>;
  };
}
interface TooltipProps {
  dataPoints: Array<DataPoint>;
  impactCategory: ImpactCategory;
  intl: IntlShape;
}

function Tooltip(props: TooltipProps) {
  const { dataPoints, impactCategory, intl } = props;

  const itemCount =
    dataPoints[0].dataset.formattedBars[dataPoints[0].dataIndex].count;
  const itemCountString =
    itemCount === 1
      ? intl.formatMessage({
          id: "components/AverageImpactIntensityByTag:itemCountSingle",
          defaultMessage: "product",
        })
      : intl.formatMessage({
          id: "components/AverageImpactIntensityByTag:itemCountPlural",
          defaultMessage: "products",
        });

  return (
    <div className="AverageIntensityByTag_Tooltip">
      <Label
        colourSetting="colour"
        impactCategory={impactCategory}
        impactRating={
          dataPoints[0].dataset.formattedBars[dataPoints[0].dataIndex]
            .impactRating
        }
        type="letterRating"
        width={46}
        locale="en-GB"
      />
      <div>
        <div className="AverageIntensityByTag_Tooltip_Label">
          {dataPoints[0].label}
        </div>
        <div className="AverageIntensityByTag_Tooltip_Impact">
          <div className="AverageIntensityByTag_Tooltip_ImpactValue">
            {dataPoints[0].raw.toFixed(2)}
          </div>
          <div className="small-copy">
            {impactCategoryToEffectTypePerKg(impactCategory).unitString(intl)}
          </div>
        </div>
      </div>
      <div className="AverageIntensityByTag_Tooltip_ItemCount">
        <div className="small-copy-medium">{itemCount}</div>
        <div className="small-copy">{itemCountString}</div>
      </div>
    </div>
  );
}
