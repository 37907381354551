import { ImpactCategory } from "../../../../domain/impactCategories";
import {
  ImpactRating,
  useImpactRatingToColor,
} from "../../../../domain/impactRatings";
import { graphikSemiboldWebCssString } from "../../../../fonts/graphik-web/graphikSemiboldWebCssString";
import {
  darkTurquoise,
  foodstepsTurquoise,
  mint,
} from "../../../graphs/colors";
import { impactRatingLetterColor } from "../../impactRatingLetterColor";
import { impactCategoryTitle, impactRatingToLetter } from "./translations";

const lineHeight = 10;

interface TitledRatingScaleProps {
  greyscale?: boolean;
  impactCategory: ImpactCategory;
  impactRating: ImpactRating;
  width: number;
  locale: string | null;
}

export default function TitledRatingScale(props: TitledRatingScaleProps) {
  const {
    greyscale = false,
    impactCategory,
    impactRating,
    width,
    locale,
  } = props;

  const impactCategoryTitleOver2Lines =
    locale === "fr-FR" &&
    (impactCategory === ImpactCategory.LAND_USE ||
      impactCategory === ImpactCategory.WATER_USE);
  const setHeight = (defaultHeight: number) => {
    return impactCategoryTitleOver2Lines
      ? defaultHeight + lineHeight
      : defaultHeight;
  };

  return (
    <svg
      width={width}
      viewBox={`0 0 135 ${setHeight(62)}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <style>{graphikSemiboldWebCssString}</style>
      <g
        transform={
          impactCategoryTitleOver2Lines ? `translate(0, ${lineHeight / 2})` : ""
        }
      >
        <ImpactCategoryIllustration
          greyscale={greyscale}
          impactCategory={impactCategory}
        />
      </g>
      <rect
        x="45.96"
        y="1"
        width="88"
        height={setHeight(60)}
        rx="7"
        fill="white"
      />
      <rect
        x="45.96"
        y="1"
        width="88"
        height={setHeight(60)}
        rx="7"
        stroke="black"
        strokeWidth="2"
      />
      <Title impactCategory={impactCategory} locale={locale} />
      <g
        transform={
          impactCategoryTitleOver2Lines ? `translate(0, ${lineHeight})` : ""
        }
      >
        <ImpactRatingScale greyscale={greyscale} impactRating={impactRating} />
      </g>
      <defs>
        <clipPath id="clip0_150_3128">
          <rect width="66.96" height="62" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

interface ImpactRatingScaleProps {
  greyscale: boolean;
  impactRating: ImpactRating;
}

function ImpactRatingScale(props: ImpactRatingScaleProps) {
  const { greyscale, impactRating } = props;

  const impactRatingToColor = useImpactRatingToColor(greyscale);

  const ratingLetterColor = impactRatingLetterColor(impactRating, greyscale);
  const ratingLetter = impactRatingToLetter(impactRating);

  const ratingLetterY = 45.5;
  const ratingLetterXA = 66;
  const ratingLetterFontSize = 18;
  const distanceBetweenRatingLetters = 12;

  switch (impactRating) {
    case "VLOW":
      return (
        <>
          <rect
            x="54"
            y="25"
            width="24"
            height="28"
            rx="5"
            fill={impactRatingToColor("VLOW")}
            stroke="black"
            strokeWidth="2"
          />
          <text
            x={ratingLetterXA}
            y={ratingLetterY}
            textAnchor="middle"
            fontFamily="Graphik Web"
            fontWeight={600}
            fontSize={ratingLetterFontSize}
            fill={ratingLetterColor}
          >
            {ratingLetter}
          </text>
          <path d="M82 30H91V48H82V30Z" fill={impactRatingToColor("LOW")} />
          <rect
            x="94"
            y="30"
            width="9"
            height="18"
            fill={impactRatingToColor("MEDIUM")}
          />
          <rect
            x="106"
            y="30"
            width="9"
            height="18"
            fill={impactRatingToColor("HIGH")}
          />
          <path
            d="M118 30H123C125.209 30 127 31.7909 127 34V44C127 46.2091 125.209 48 123 48H118V30Z"
            fill={impactRatingToColor("VHIGH")}
          />
        </>
      );
    case "LOW":
      return (
        <>
          <path
            d="M53 34C53 31.7909 54.7909 30 57 30H62V48H57C54.7909 48 53 46.2091 53 44V34Z"
            fill={impactRatingToColor("VLOW")}
          />
          <rect
            x="66"
            y="25"
            width="24"
            height="28"
            rx="5"
            fill={impactRatingToColor("LOW")}
            stroke="black"
            strokeWidth="2"
          />
          <text
            x={ratingLetterXA + distanceBetweenRatingLetters}
            y={ratingLetterY}
            textAnchor="middle"
            fontFamily="Graphik Web"
            fontWeight={600}
            fontSize={ratingLetterFontSize}
            fill={ratingLetterColor}
          >
            {ratingLetter}
          </text>
          <rect
            x="94"
            y="30"
            width="9"
            height="18"
            fill={impactRatingToColor("MEDIUM")}
          />
          <rect
            x="106"
            y="30"
            width="9"
            height="18"
            fill={impactRatingToColor("HIGH")}
          />
          <path
            d="M118 30H123C125.209 30 127 31.7909 127 34V44C127 46.2091 125.209 48 123 48H118V30Z"
            fill={impactRatingToColor("VHIGH")}
          />
        </>
      );
    case "MEDIUM":
      return (
        <>
          <path
            d="M53 34C53 31.7909 54.7909 30 57 30H62V48H57C54.7909 48 53 46.2091 53 44V34Z"
            fill={impactRatingToColor("VLOW")}
          />
          <rect
            x="65"
            y="30"
            width="9"
            height="18"
            fill={impactRatingToColor("LOW")}
          />
          <rect
            x="78"
            y="25"
            width="24"
            height="28"
            rx="5"
            fill={impactRatingToColor("MEDIUM")}
            stroke="black"
            strokeWidth="2"
          />
          <text
            x={ratingLetterXA + 2 * distanceBetweenRatingLetters}
            y={ratingLetterY}
            textAnchor="middle"
            fontFamily="Graphik Web"
            fontWeight={600}
            fontSize={ratingLetterFontSize}
            fill={ratingLetterColor}
          >
            {ratingLetter}
          </text>
          <rect
            x="106"
            y="30"
            width="9"
            height="18"
            fill={impactRatingToColor("HIGH")}
          />
          <path
            d="M118 30H123C125.209 30 127 31.7909 127 34V44C127 46.2091 125.209 48 123 48H118V30Z"
            fill={impactRatingToColor("VHIGH")}
          />
        </>
      );
    case "HIGH":
      return (
        <>
          <path
            d="M53 34C53 31.7909 54.7909 30 57 30H62V48H57C54.7909 48 53 46.2091 53 44V34Z"
            fill={impactRatingToColor("VLOW")}
          />
          <rect
            x="65"
            y="30"
            width="9"
            height="18"
            fill={impactRatingToColor("LOW")}
          />
          <rect
            x="77"
            y="30"
            width="9"
            height="18"
            fill={impactRatingToColor("MEDIUM")}
          />
          <rect
            x="90"
            y="25"
            width="24"
            height="28"
            rx="5"
            fill={impactRatingToColor("HIGH")}
            stroke="black"
            strokeWidth="2"
          />
          <text
            x={ratingLetterXA + 3 * distanceBetweenRatingLetters}
            y={ratingLetterY}
            textAnchor="middle"
            fontFamily="Graphik Web"
            fontWeight={600}
            fontSize={ratingLetterFontSize}
            fill={ratingLetterColor}
          >
            {ratingLetter}
          </text>
          <path
            d="M118 30H123C125.209 30 127 31.7909 127 34V44C127 46.2091 125.209 48 123 48H118V30Z"
            fill={impactRatingToColor("VHIGH")}
          />
        </>
      );
    case "VHIGH":
      return (
        <>
          <path
            d="M53 34C53 31.7909 54.7909 30 57 30H62V48H57C54.7909 48 53 46.2091 53 44V34Z"
            fill={impactRatingToColor("VLOW")}
          />
          <rect
            x="65"
            y="30"
            width="9"
            height="18"
            fill={impactRatingToColor("LOW")}
          />
          <rect
            x="77"
            y="30"
            width="9"
            height="18"
            fill={impactRatingToColor("MEDIUM")}
          />
          <path d="M89 30H98V48H89V30Z" fill={impactRatingToColor("HIGH")} />
          <rect
            x="102"
            y="25"
            width="24"
            height="28"
            rx="5"
            fill={impactRatingToColor("VHIGH")}
            stroke="black"
            strokeWidth="2"
          />
          <text
            x={ratingLetterXA + 4 * distanceBetweenRatingLetters}
            y={ratingLetterY}
            textAnchor="middle"
            fontFamily="Graphik Web"
            fontWeight={600}
            fontSize={ratingLetterFontSize}
            fill={ratingLetterColor}
          >
            {ratingLetter}
          </text>
        </>
      );
  }
}

interface ImpactCategoryIllustrationProps {
  greyscale: boolean;
  impactCategory: ImpactCategory;
}

function ImpactCategoryIllustration(props: ImpactCategoryIllustrationProps) {
  const { greyscale, impactCategory } = props;

  switch (impactCategory) {
    case ImpactCategory.GHG:
      return (
        <>
          <path
            d="M35.7398 60.2951C51.919 60.2951 65.0348 47.1793 65.0348 31.0001C65.0348 14.8209 51.919 1.70508 35.7398 1.70508C19.5606 1.70508 6.44482 14.8209 6.44482 31.0001C6.44482 47.1793 19.5606 60.2951 35.7398 60.2951Z"
            fill={foodstepsTurquoise(greyscale)}
            stroke="black"
            strokeWidth="2"
          />
          <path
            d="M8.71387 42.3242H62.7655"
            stroke="black"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M29.0933 19.1301C29.1429 17.2081 30.7177 15.6643 32.6521 15.6643C32.9063 15.6643 33.1512 15.6922 33.3899 15.7418C34.044 12.0683 37.2525 9.28142 41.112 9.28142C41.143 9.28142 41.1709 9.28142 41.2019 9.28142C42.2001 7.37492 44.1965 6.06982 46.4998 6.06982C49.8013 6.06982 52.4766 8.74512 52.4766 12.0466C52.4766 12.0466 52.4766 12.0497 52.4766 12.0528C52.5448 12.0528 52.613 12.0466 52.6812 12.0466C55.3441 12.0466 57.5048 14.2073 57.5048 16.8702C57.5048 17.6297 57.3281 18.3489 57.015 18.9875C59.123 19.2913 60.7598 21.1172 60.7598 23.3058C60.7598 25.7052 58.7944 27.6706 56.395 27.6706H30.355C27.9556 27.6706 25.9902 25.7052 25.9902 23.3058C25.9902 21.3435 27.3046 19.6726 29.0933 19.127V19.1301Z"
            fill={mint(greyscale)}
            stroke="black"
            strokeWidth="2"
            strokeLinejoin="round"
          />
          <path
            d="M19.2849 31.4618C17.5241 27.7697 19.9328 24.8712 19.9328 24.8712C14.5109 24.8712 14.8798 19.6043 14.8798 19.6043C10.7692 22.4563 7.74979 19.0494 7.74979 19.0494C0.284995 19.688 2.04579 12.9765 2.04579 12.9765C0.368695 5.51169 7.83349 5.59539 7.83349 5.59539C15.2983 -0.778212 20.3327 6.60289 20.3327 6.60289C30.1473 6.35179 27.7138 16.4175 27.7138 16.4175C32.1468 19.719 27.8905 23.5692 27.8905 23.5692C27.8905 23.5692 31.7221 25.702 28.526 31.4618"
            fill={mint(greyscale)}
          />
          <path
            d="M19.2849 31.4618C17.5241 27.7697 19.9328 24.8712 19.9328 24.8712C14.5109 24.8712 14.8798 19.6043 14.8798 19.6043C10.7692 22.4563 7.74979 19.0494 7.74979 19.0494C0.284995 19.688 2.04579 12.9765 2.04579 12.9765C0.368695 5.51169 7.83349 5.59539 7.83349 5.59539C15.2983 -0.778212 20.3327 6.60289 20.3327 6.60289C30.1473 6.35179 27.7138 16.4175 27.7138 16.4175C32.1468 19.719 27.8905 23.5692 27.8905 23.5692C27.8905 23.5692 31.7221 25.702 28.526 31.4618"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M32.2584 32.7949C32.1375 31.9083 31.3811 31.248 30.4883 31.248H18.2495C17.3536 31.248 16.5972 31.9083 16.4794 32.7949L14.0552 50.6943C19.4151 56.5936 27.1465 60.2981 35.7428 60.2981C35.8234 60.2981 35.904 60.2981 35.9877 60.2981L32.2615 32.8011L32.2584 32.7949Z"
            fill={darkTurquoise(greyscale)}
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      );
    case ImpactCategory.LAND_USE:
      return (
        <>
          <path
            d="M35.7398 60.2951C51.919 60.2951 65.0348 47.1793 65.0348 31.0001C65.0348 14.8209 51.919 1.70508 35.7398 1.70508C19.5606 1.70508 6.44482 14.8209 6.44482 31.0001C6.44482 47.1793 19.5606 60.2951 35.7398 60.2951Z"
            fill={foodstepsTurquoise(greyscale)}
            stroke="black"
            strokeWidth="2"
          />
          <path
            d="M31.8682 1.87231L42.9569 59.399"
            stroke="black"
            strokeWidth="2"
            strokeLinejoin="round"
          />
          <path
            d="M4.12944 36.3288H12.0778C12.9241 36.3288 13.6123 37.1348 13.6123 38.1268V45.6319C14.471 45.3932 14.4369 45.2599 15.5932 45.2599C16.6751 45.2599 20.7299 45.3746 21.539 45.5854V38.1299C21.539 37.1379 22.2272 36.3319 23.0735 36.3319H31.0219C33.2136 36.3319 34.2676 33.6411 32.6587 32.1531L25.5814 25.609H28.3094C30.5011 25.609 31.5551 22.9182 29.9462 21.4302L23.6098 15.5712H25.3489C27.5406 15.5712 28.5946 12.8804 26.9857 11.3924L19.7875 5.05294C18.5227 3.94004 16.6286 3.94004 15.3669 5.05294L8.16874 11.3924C6.55984 12.8804 7.61074 15.5712 9.80554 15.5712H11.5446L5.20824 21.4302C3.59934 22.9182 4.65024 25.609 6.84504 25.609H9.57304L2.49574 32.1531C0.886836 33.6411 1.93774 36.3319 4.13254 36.3319L4.12944 36.3288Z"
            fill={mint(greyscale)}
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M35.8576 21.4334H63.4352C59.4703 9.951 48.5676 1.7019 35.7398 1.7019C34.4657 1.7019 33.2102 1.7825 31.9795 1.9406L35.8576 21.4303V21.4334Z"
            fill={mint(greyscale)}
            stroke="black"
            strokeWidth="2"
          />
          <path
            d="M57.6755 43.6636C54.4329 43.6636 51.4073 44.4975 48.8188 45.9328C48.0655 46.3513 47.1231 46.3513 46.3698 45.9328C43.7813 44.4975 40.7557 43.6636 37.5131 43.6636C34.2705 43.6636 31.2449 44.4975 28.6564 45.9328C27.9031 46.3513 26.9607 46.3513 26.2074 45.9328C23.6189 44.4975 20.5933 43.6636 17.3507 43.6636C14.7529 43.6636 12.2915 44.1999 10.0874 45.1516C15.0784 54.1788 24.6946 60.2951 35.743 60.2951C46.7914 60.2951 57.1051 53.7262 61.9225 44.1565C60.5647 43.8372 59.1418 43.6636 57.6755 43.6636Z"
            fill={darkTurquoise(greyscale)}
            stroke="black"
            strokeWidth="2"
          />
        </>
      );
    case ImpactCategory.WATER_USE:
      return (
        <>
          <path
            d="M35.7398 60.2951C51.919 60.2951 65.0348 47.1793 65.0348 31.0001C65.0348 14.8209 51.919 1.70508 35.7398 1.70508C19.5606 1.70508 6.44482 14.8209 6.44482 31.0001C6.44482 47.1793 19.5606 60.2951 35.7398 60.2951Z"
            fill={foodstepsTurquoise(greyscale)}
            stroke="black"
            strokeWidth="2"
          />
          <path
            d="M35.7399 60.2949C46.19 60.2949 55.3598 54.8234 60.543 46.5898C58.0878 44.271 56.4975 41.2516 56.4975 41.2516C46.5341 58.5992 37.1876 41.2516 37.1876 41.2516C26.2911 57.9482 17.8777 40.2224 17.8777 40.2224C15.4907 43.2666 13.1688 45.3219 10.9492 46.6208C16.1386 54.8358 25.2991 60.2949 35.7337 60.2949H35.7399Z"
            fill={darkTurquoise(greyscale)}
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M28.3398 26.6289C28.3398 20.6552 35.2125 8.53421 35.2125 8.53421C35.9534 6.91601 38.4954 6.91601 39.2363 8.53421C39.2363 8.53421 46.109 20.4506 46.109 26.6289C46.109 31.5362 42.1317 36.1242 37.2244 36.1242C32.3171 36.1242 28.3398 31.5362 28.3398 26.6289Z"
            fill={mint(greyscale)}
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M1.60254 35.8794C1.60254 25.8602 13.1283 5.53657 13.1283 5.53657C14.3714 2.82407 18.6308 2.82407 19.8739 5.53657C19.8739 5.53657 31.3997 25.5192 31.3997 35.8794C31.3997 44.1068 24.7285 51.8041 16.5011 51.8041C8.27374 51.8041 1.60254 44.1068 1.60254 35.8794Z"
            fill={mint(greyscale)}
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      );
  }
}

interface TitleProps {
  impactCategory: ImpactCategory;
  locale: string | null;
}

function Title(props: TitleProps) {
  const { impactCategory, locale } = props;

  const titleString = impactCategoryTitle(impactCategory, locale, true);

  const y = 17;

  const commonProps = {
    y,
    x: 90,
    textAnchor: "middle",
    fontFamily: "Graphik Web",
    fontWeight: 500,
    fontSize: impactCategoryTitleFontSize(impactCategory, locale),
    fill: "black",
  };

  if (
    locale === "fr-FR" &&
    (impactCategory === ImpactCategory.LAND_USE ||
      impactCategory === ImpactCategory.WATER_USE)
  ) {
    const words = titleString.split(" ");
    const firstWord = words.shift();
    return (
      <>
        <text {...commonProps}>{firstWord}</text>
        <text {...commonProps} y={y + lineHeight}>
          {words.join(" ")}
        </text>
      </>
    );
  } else {
    return <text {...commonProps}>{titleString}</text>;
  }
}

function impactCategoryTitleFontSize(
  impactCategory: ImpactCategory,
  locale: string | null
): number {
  if (locale === "de-DE" && impactCategory === ImpactCategory.WATER_USE) {
    return 9;
  }
  return 10;
}
