import { ImpactCategory } from "../../../../domain/impactCategories";
import {
  ImpactRating,
  useImpactRatingToColor,
} from "../../../../domain/impactRatings";
import { graphikSemiboldWebCssString } from "../../../../fonts/graphik-web/graphikSemiboldWebCssString";
import {
  darkTurquoise,
  foodstepsTurquoise,
  mint,
} from "../../../graphs/colors";
import { impactRatingLetterColor } from "../../impactRatingLetterColor";
import {
  impactRatingToLetter,
  impactRatingToName,
  impactCategoryTitle,
} from "./translations";

const lineHeight = 14;

interface DescriptiveRatingScaleProps {
  greyscale?: boolean;
  impactCategory: ImpactCategory;
  impactRating: ImpactRating;
  locale: string | null;
  width: number;
}

export default function DescriptiveRatingScale(
  props: DescriptiveRatingScaleProps
) {
  const {
    greyscale = false,
    impactCategory,
    impactRating,
    locale,
    width,
  } = props;

  const impactCategoryTitleOver2Lines =
    locale === "fr-FR" &&
    (impactCategory === ImpactCategory.LAND_USE ||
      impactCategory === ImpactCategory.WATER_USE);
  const setHeight = (defaultHeight: number) => {
    return impactCategoryTitleOver2Lines
      ? defaultHeight + lineHeight
      : defaultHeight;
  };

  return (
    <svg
      width={width}
      viewBox={`0 0 98 ${setHeight(154)}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <style>{graphikSemiboldWebCssString}</style>

      <rect
        x="1"
        y="23"
        width="96"
        height={setHeight(130)}
        rx="9"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M2 32C2 27.5817 5.58172 24 10 24H88C92.4183 24 96 27.5817 96 32V88H2V32Z"
        fill="white"
      />
      <ImpactCategoryIllustration
        greyscale={greyscale}
        impactCategory={impactCategory}
      />

      <text
        x={49}
        y={60}
        textAnchor="middle"
        fontFamily="Graphik Web"
        fontWeight={600}
        fontSize={impactRatingFontSize(impactRating, locale)}
        fill="black"
      >
        {impactRatingToName(impactRating, locale)}
      </text>
      <Title impactCategory={impactCategory} locale={locale} />

      <rect
        width="94"
        height={setHeight(40)}
        transform={`translate(2 ${setHeight(88)})`}
        fill="white"
      />

      <g
        transform={
          impactCategoryTitleOver2Lines ? `translate(0 ${lineHeight})` : ""
        }
      >
        <ImpactRatingScale greyscale={greyscale} impactRating={impactRating} />

        <path
          d="M2 128H96V144C96 148.418 92.4183 152 88 152H10C5.58172 152 2 148.418 2 144V128Z"
          fill={foodstepsTurquoise(greyscale)}
        />
        <path
          d="M12.9453 143.672H15.0639V140.05H18.1372V138.454H15.0639V135.983H18.948V134.322H12.9453V143.672Z"
          fill="white"
        />
        <path
          d="M23.0543 142.378C21.9819 142.378 21.3803 141.593 21.3803 140.285V140.18C21.3803 138.873 22.0081 138.114 23.0543 138.114C24.1136 138.114 24.7282 138.899 24.7282 140.207V140.298C24.7282 141.593 24.1136 142.378 23.0543 142.378ZM23.0412 143.816C25.0944 143.816 26.6506 142.443 26.6506 140.285V140.18C26.6506 138.049 25.1075 136.676 23.0543 136.676C21.0011 136.676 19.4448 138.075 19.4448 140.22V140.324C19.4448 142.456 21.0011 143.816 23.0412 143.816Z"
          fill="white"
        />
        <path
          d="M31.1129 142.378C30.0405 142.378 29.4389 141.593 29.4389 140.285V140.18C29.4389 138.873 30.0667 138.114 31.1129 138.114C32.1722 138.114 32.7868 138.899 32.7868 140.207V140.298C32.7868 141.593 32.1722 142.378 31.1129 142.378ZM31.0998 143.816C33.153 143.816 34.7092 142.443 34.7092 140.285V140.18C34.7092 138.049 33.1661 136.676 31.1129 136.676C29.0597 136.676 27.5034 138.075 27.5034 140.22V140.324C27.5034 142.456 29.0597 143.816 31.0998 143.816Z"
          fill="white"
        />
        <path
          d="M38.5438 143.816C39.5508 143.816 40.3877 143.228 40.7408 142.534V143.672H42.624V133.733H40.7408V137.853C40.3616 137.16 39.6815 136.676 38.583 136.676C36.8698 136.676 35.5751 137.996 35.5751 140.246V140.35C35.5751 142.626 36.8829 143.816 38.5438 143.816ZM39.1061 142.338C38.1645 142.338 37.5106 141.671 37.5106 140.311V140.207C37.5106 138.847 38.0991 138.127 39.1584 138.127C40.1916 138.127 40.7931 138.82 40.7931 140.18V140.285C40.7931 141.671 40.1 142.338 39.1061 142.338Z"
          fill="white"
        />
        <path
          d="M46.7921 143.816C48.5968 143.816 49.6692 143.044 49.6692 141.567C49.6692 140.102 48.7276 139.67 47.0144 139.422C46.0205 139.278 45.6936 139.108 45.6936 138.677C45.6936 138.258 46.0467 137.97 46.6613 137.97C47.3152 137.97 47.6291 138.232 47.7337 138.794H49.473C49.303 137.225 48.1653 136.676 46.6483 136.676C45.2489 136.676 43.9412 137.382 43.9412 138.807C43.9412 140.141 44.6474 140.664 46.4652 140.939C47.446 141.083 47.8514 141.279 47.8514 141.75C47.8514 142.221 47.5114 142.495 46.779 142.495C45.9551 142.495 45.6544 142.142 45.5759 141.527H43.8104C43.8758 143.005 44.9482 143.816 46.7921 143.816Z"
          fill="white"
        />
        <path
          d="M53.3696 143.803C53.8796 143.803 54.2589 143.711 54.5204 143.62V142.155C54.2981 142.247 54.0889 142.286 53.8011 142.286C53.3173 142.286 53.0426 142.024 53.0426 141.475V138.18H54.4812V136.833H53.0426V135.355H51.1595V136.833H50.2832V138.18H51.1595V141.645C51.1595 143.071 51.931 143.803 53.3696 143.803Z"
          fill="white"
        />
        <path
          d="M58.9531 143.816C60.8232 143.816 62.0002 142.992 62.2226 141.514H60.4309C60.3132 142.09 59.8817 142.469 59.0055 142.469C57.9723 142.469 57.3577 141.815 57.3054 140.677H62.2356V140.154C62.2356 137.709 60.6663 136.676 58.9008 136.676C56.913 136.676 55.3829 138.075 55.3829 140.233V140.337C55.3829 142.521 56.8869 143.816 58.9531 143.816ZM57.3315 139.513C57.4754 138.546 58.0508 137.983 58.9008 137.983C59.8032 137.983 60.3263 138.48 60.3917 139.513H57.3315Z"
          fill="white"
        />
        <path
          d="M63.4769 146H65.3732V142.626C65.7263 143.293 66.4978 143.816 67.5571 143.816C69.2572 143.816 70.5389 142.548 70.5389 140.285V140.18C70.5389 137.918 69.2311 136.676 67.5571 136.676C66.5502 136.676 65.7524 137.251 65.3732 137.905V136.833H63.4769V146ZM66.9948 142.338C65.9617 142.338 65.3078 141.671 65.3078 140.298V140.194C65.3078 138.82 65.9878 138.127 66.9817 138.127C67.9364 138.127 68.6164 138.807 68.6164 140.194V140.298C68.6164 141.632 68.041 142.338 66.9948 142.338Z"
          fill="white"
        />
        <path
          d="M74.2885 143.816C76.0932 143.816 77.1656 143.044 77.1656 141.567C77.1656 140.102 76.224 139.67 74.5108 139.422C73.5169 139.278 73.1899 139.108 73.1899 138.677C73.1899 138.258 73.543 137.97 74.1577 137.97C74.8116 137.97 75.1254 138.232 75.2301 138.794H76.9694C76.7994 137.225 75.6616 136.676 74.1446 136.676C72.7453 136.676 71.4375 137.382 71.4375 138.807C71.4375 140.141 72.1437 140.664 73.9615 140.939C74.9424 141.083 75.3478 141.279 75.3478 141.75C75.3478 142.221 75.0077 142.495 74.2754 142.495C73.4515 142.495 73.1507 142.142 73.0722 141.527H71.3068C71.3721 143.005 72.4445 143.816 74.2885 143.816Z"
          fill="white"
        />
        <path
          d="M81.6594 136.848L81.6591 136.611C81.6592 136.475 81.7241 136.381 81.8534 136.33C82.3715 136.125 82.6021 135.528 82.3508 135.044C82.3036 134.953 82.2427 134.871 82.1695 134.799C81.9584 134.59 81.7455 134.383 81.5343 134.174C81.5114 134.152 81.4974 134.149 81.4726 134.172C81.3439 134.291 81.2137 134.408 81.0837 134.526C80.9907 134.61 80.8958 134.692 80.804 134.777C80.6159 134.952 80.5118 135.165 80.5002 135.419C80.4876 135.697 80.5844 135.936 80.7845 136.133C80.8726 136.22 80.9768 136.285 81.0939 136.33C81.2219 136.379 81.2922 136.48 81.2923 136.613C81.2924 136.778 81.2925 136.943 81.2922 137.108C81.2922 137.13 81.2975 137.156 81.2679 137.165C81.2386 137.174 81.2279 137.15 81.2139 137.132C81.0416 136.915 80.8181 136.789 80.5353 136.767C80.4535 136.76 80.4537 136.762 80.4615 136.84L80.4618 136.843C80.4793 137.018 80.5388 137.177 80.6458 137.319C80.7651 137.476 80.9221 137.584 81.1118 137.646C81.2294 137.685 81.2919 137.769 81.2923 137.891C81.2926 137.979 81.2923 138.068 81.2923 138.157C81.2923 138.415 81.1188 138.571 80.8599 138.518C80.9766 138.625 81.061 138.693 81.1715 138.72C81.4565 138.79 81.6728 138.879 82.0895 138.523C81.874 138.564 81.6815 138.371 81.6803 138.157C81.6799 138.054 81.6803 137.985 81.6803 137.882C81.6803 137.767 81.7423 137.683 81.8578 137.646C82.0279 137.591 82.1505 137.498 82.2657 137.365C82.3966 137.213 82.472 137.038 82.4862 136.839C82.4918 136.762 82.4915 136.761 82.4129 136.767C82.2011 136.782 82.0174 136.861 81.8597 136.999C81.8102 137.043 81.767 137.092 81.7272 137.143C81.715 137.159 81.7025 137.171 81.6803 137.164C81.6593 137.157 81.6593 137.14 81.6594 137.123V136.848Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M81.4875 138.58C83.221 138.58 84.6262 137.201 84.6262 135.5C84.6262 133.799 83.221 132.42 81.4875 132.42C79.7541 132.42 78.3489 133.799 78.3489 135.5C78.3489 137.201 79.7541 138.58 81.4875 138.58ZM81.4875 139C83.4573 139 85.0542 137.433 85.0542 135.5C85.0542 133.567 83.4573 132 81.4875 132C79.5177 132 77.9209 133.567 77.9209 135.5C77.9209 137.433 79.5177 139 81.4875 139Z"
          fill="white"
        />
      </g>
    </svg>
  );
}

interface ImpactRatingScaleProps {
  greyscale: boolean;
  impactRating: ImpactRating;
}

function ImpactRatingScale(props: ImpactRatingScaleProps) {
  const { greyscale, impactRating } = props;

  const impactRatingToColor = useImpactRatingToColor(greyscale);
  const ratingLetter = impactRatingToLetter(impactRating);
  const ratingLetterColor = impactRatingLetterColor(impactRating, greyscale);

  const ratingLetterY = 111;
  const ratingLetterXA = 25;
  const ratingLetterFontSize = 20;
  const distanceBetweenRatingLetters = 12;

  switch (impactRating) {
    case "VLOW":
      return (
        <>
          <rect
            x="12"
            y="89"
            width="26"
            height="30"
            rx="5"
            fill={impactRatingToColor("VLOW")}
            stroke="black"
            strokeWidth="2"
          />
          <text
            x={ratingLetterXA}
            y={ratingLetterY}
            textAnchor="middle"
            fontFamily="Graphik Web"
            fontWeight={600}
            fontSize={ratingLetterFontSize}
            fill={ratingLetterColor}
          >
            {ratingLetter}
          </text>
          <path d="M42 94H51V114H42V94Z" fill={impactRatingToColor("LOW")} />
          <rect
            x="54"
            y="94"
            width="9"
            height="20"
            fill={impactRatingToColor("MEDIUM")}
          />
          <rect
            x="66"
            y="94"
            width="9"
            height="20"
            fill={impactRatingToColor("HIGH")}
          />
          <path
            d="M78 94H83C85.2091 94 87 95.7909 87 98V110C87 112.209 85.2091 114 83 114H78V94Z"
            fill={impactRatingToColor("VHIGH")}
          />
        </>
      );
    case "LOW":
      return (
        <>
          <path
            d="M11 98C11 95.7909 12.7909 94 15 94H20V114H15C12.7909 114 11 112.209 11 110V98Z"
            fill={impactRatingToColor("VLOW")}
          />
          <rect
            x="24"
            y="89"
            width="26"
            height="30"
            rx="5"
            fill={impactRatingToColor("LOW")}
            stroke="black"
            strokeWidth="2"
          />
          <text
            x={ratingLetterXA + distanceBetweenRatingLetters}
            y={ratingLetterY}
            textAnchor="middle"
            fontFamily="Graphik Web"
            fontWeight={600}
            fontSize={ratingLetterFontSize}
            fill={ratingLetterColor}
          >
            {ratingLetter}
          </text>
          <rect
            x="54"
            y="94"
            width="9"
            height="20"
            fill={impactRatingToColor("MEDIUM")}
          />
          <rect
            x="66"
            y="94"
            width="9"
            height="20"
            fill={impactRatingToColor("HIGH")}
          />
          <path
            d="M78 94H83C85.2091 94 87 95.7909 87 98V110C87 112.209 85.2091 114 83 114H78V94Z"
            fill={impactRatingToColor("VHIGH")}
          />
        </>
      );
    case "MEDIUM":
      return (
        <>
          <path
            d="M11 98C11 95.7909 12.7909 94 15 94H20V114H15C12.7909 114 11 112.209 11 110V98Z"
            fill={impactRatingToColor("VLOW")}
          />
          <rect
            x="23"
            y="94"
            width="9"
            height="20"
            fill={impactRatingToColor("LOW")}
          />
          <rect
            x="36"
            y="89"
            width="26"
            height="30"
            rx="5"
            fill={impactRatingToColor("MEDIUM")}
            stroke="black"
            strokeWidth="2"
          />
          <text
            x={ratingLetterXA + 2 * distanceBetweenRatingLetters}
            y={ratingLetterY}
            textAnchor="middle"
            fontFamily="Graphik Web"
            fontWeight={600}
            fontSize={ratingLetterFontSize}
            fill={ratingLetterColor}
          >
            {ratingLetter}
          </text>
          <rect
            x="66"
            y="94"
            width="9"
            height="20"
            fill={impactRatingToColor("HIGH")}
          />
          <path
            d="M78 94H83C85.2091 94 87 95.7909 87 98V110C87 112.209 85.2091 114 83 114H78V94Z"
            fill={impactRatingToColor("VHIGH")}
          />
        </>
      );
    case "HIGH":
      return (
        <>
          <path
            d="M11 98C11 95.7909 12.7909 94 15 94H20V114H15C12.7909 114 11 112.209 11 110V98Z"
            fill={impactRatingToColor("VLOW")}
          />
          <rect
            x="23"
            y="94"
            width="9"
            height="20"
            fill={impactRatingToColor("LOW")}
          />
          <rect
            x="35"
            y="94"
            width="9"
            height="20"
            fill={impactRatingToColor("MEDIUM")}
          />
          <rect
            x="48"
            y="89"
            width="26"
            height="30"
            rx="5"
            fill={impactRatingToColor("HIGH")}
            stroke="black"
            strokeWidth="2"
          />
          <text
            x={ratingLetterXA + 3 * distanceBetweenRatingLetters}
            y={ratingLetterY}
            textAnchor="middle"
            fontFamily="Graphik Web"
            fontWeight={600}
            fontSize={ratingLetterFontSize}
            fill={ratingLetterColor}
          >
            {ratingLetter}
          </text>
          <path
            d="M78 94H83C85.2091 94 87 95.7909 87 98V110C87 112.209 85.2091 114 83 114H78V94Z"
            fill={impactRatingToColor("VHIGH")}
          />
        </>
      );
    case "VHIGH":
      return (
        <>
          <path
            d="M11 98C11 95.7909 12.7909 94 15 94H20V114H15C12.7909 114 11 112.209 11 110V98Z"
            fill={impactRatingToColor("VLOW")}
          />
          <rect
            x="23"
            y="94"
            width="9"
            height="20"
            fill={impactRatingToColor("LOW")}
          />
          <rect
            x="35"
            y="94"
            width="9"
            height="20"
            fill={impactRatingToColor("MEDIUM")}
          />
          <path d="M47 94H56V114H47V94Z" fill={impactRatingToColor("HIGH")} />
          <rect
            x="60"
            y="89"
            width="26"
            height="30"
            rx="5"
            fill={impactRatingToColor("VHIGH")}
            stroke="black"
            strokeWidth="2"
          />
          <text
            x={ratingLetterXA + 4 * distanceBetweenRatingLetters}
            y={ratingLetterY}
            textAnchor="middle"
            fontFamily="Graphik Web"
            fontWeight={600}
            fontSize={ratingLetterFontSize}
            fill={ratingLetterColor}
          >
            {ratingLetter}
          </text>
        </>
      );
  }
}

interface ImpactCategoryIllustrationProps {
  greyscale: boolean;
  impactCategory: ImpactCategory;
}

function ImpactCategoryIllustration(props: ImpactCategoryIllustrationProps) {
  const { greyscale, impactCategory } = props;

  switch (impactCategory) {
    case ImpactCategory.GHG:
      return (
        <>
          <path
            d="M50.5305 40.845C61.4906 40.845 70.3755 31.9601 70.3755 21C70.3755 10.0399 61.4906 1.15503 50.5305 1.15503C39.5705 1.15503 30.6855 10.0399 30.6855 21C30.6855 31.9601 39.5705 40.845 50.5305 40.845Z"
            fill={foodstepsTurquoise(greyscale)}
            stroke="black"
            strokeWidth="2"
          />
          <path
            d="M31.8301 28.6714H68.5171"
            stroke="black"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M39.384 21.313C38.1912 18.8119 39.8229 16.8484 39.8229 16.8484C36.15 16.8484 36.3999 13.2805 36.3999 13.2805C33.6153 15.2125 31.5699 12.9046 31.5699 12.9046C26.5131 13.3372 27.7059 8.79071 27.7059 8.79071C26.5698 3.73391 31.6266 3.79061 31.6266 3.79061C36.6834 -0.526986 40.0938 4.47311 40.0938 4.47311C46.7424 4.30301 45.0939 11.1217 45.0939 11.1217C48.0969 13.3582 45.2136 15.9664 45.2136 15.9664C45.2136 15.9664 47.8092 17.4112 45.6441 21.313"
            fill={mint(greyscale)}
          />
          <path
            d="M39.384 21.313C38.1912 18.8119 39.8229 16.8484 39.8229 16.8484C36.15 16.8484 36.3999 13.2805 36.3999 13.2805C33.6153 15.2125 31.5699 12.9046 31.5699 12.9046C26.5131 13.3372 27.7059 8.79071 27.7059 8.79071C26.5698 3.73391 31.6266 3.79061 31.6266 3.79061C36.6834 -0.526986 40.0938 4.47311 40.0938 4.47311C46.7424 4.30301 45.0939 11.1217 45.0939 11.1217C48.0969 13.3582 45.2136 15.9664 45.2136 15.9664C45.2136 15.9664 47.8092 17.4112 45.6441 21.313"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M70.3756 21.0001C70.3756 15.5338 68.1664 10.5862 64.5922 6.99731C63.4666 7.28291 62.5279 8.03891 62.0029 9.04481C61.9819 9.04481 61.963 9.04481 61.942 9.04481C59.3275 9.04481 57.154 10.9348 56.7109 13.4212C56.5492 13.3876 56.3833 13.3687 56.2111 13.3687C54.9007 13.3687 53.8339 14.4145 53.8003 15.7165C52.5865 16.0861 51.6982 17.2159 51.6982 18.5473C51.6982 20.1727 53.0296 21.5041 54.655 21.5041H70.3735C70.3777 21.3361 70.3798 21.1681 70.3798 21.0001H70.3756Z"
            fill={mint(greyscale)}
            stroke="black"
            strokeWidth="2"
          />
          <path
            d="M48.1725 22.2159C48.0906 21.6153 47.5782 21.168 46.9734 21.168H38.6826C38.0757 21.168 37.5633 21.6153 37.4835 22.2159L35.8413 34.3413C39.4722 38.3376 44.7096 40.845 50.5329 40.845C50.5875 40.845 50.6421 40.845 50.6988 40.845L48.1746 22.218L48.1725 22.2159Z"
            fill={darkTurquoise(greyscale)}
            stroke="black"
            strokeWidth="2"
          />
        </>
      );
    case ImpactCategory.LAND_USE:
      return (
        <>
          <path
            d="M50.5305 40.845C61.4906 40.845 70.3755 31.9601 70.3755 21C70.3755 10.0399 61.4906 1.15503 50.5305 1.15503C39.5705 1.15503 30.6855 10.0399 30.6855 21C30.6855 31.9601 39.5705 40.845 50.5305 40.845Z"
            fill={foodstepsTurquoise(greyscale)}
            stroke="black"
            strokeWidth="2"
          />
          <path
            d="M47.9077 1.26831L55.4194 40.238"
            stroke="black"
            strokeWidth="2"
            strokeLinejoin="round"
          />
          <path
            d="M29.1169 24.61H34.5013C35.0746 24.61 35.5408 25.156 35.5408 25.828V30.9121C36.1225 30.7504 36.0994 30.6601 36.8827 30.6601C37.6156 30.6601 40.3624 30.7378 40.9105 30.8806V25.8301C40.9105 25.1581 41.3767 24.6121 41.95 24.6121H47.3344C48.8191 24.6121 49.5331 22.7893 48.4432 21.7813L43.6489 17.3482H45.4969C46.9816 17.3482 47.6956 15.5254 46.6057 14.5174L42.3133 10.5484H43.4914C44.9761 10.5484 45.6901 8.72559 44.6002 7.71759L39.724 3.42309C38.8672 2.66919 37.5841 2.66919 36.7294 3.42309L31.8532 7.71759C30.7633 8.72559 31.4752 10.5484 32.962 10.5484H34.1401L29.8477 14.5174C28.7578 15.5254 29.4697 17.3482 30.9565 17.3482H32.8045L28.0102 21.7813C26.9203 22.7893 27.6322 24.6121 29.119 24.6121L29.1169 24.61Z"
            fill={mint(greyscale)}
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M50.6105 14.5193H69.2921C66.6062 6.74093 59.2205 1.15283 50.5307 1.15283C49.6676 1.15283 48.8171 1.20743 47.9834 1.31453L50.6105 14.5172V14.5193Z"
            fill={mint(greyscale)}
            stroke="black"
            strokeWidth="2"
          />
          <path
            d="M65.3904 29.5786C63.1938 29.5786 61.1442 30.1435 59.3907 31.1158C58.8804 31.3993 58.242 31.3993 57.7317 31.1158C55.9782 30.1435 53.9286 29.5786 51.732 29.5786C49.5354 29.5786 47.4858 30.1435 45.7323 31.1158C45.222 31.3993 44.5836 31.3993 44.0733 31.1158C42.3198 30.1435 40.2702 29.5786 38.0736 29.5786C36.3138 29.5786 34.6464 29.9419 33.1533 30.5866C36.5343 36.7018 43.0485 40.8451 50.5329 40.8451C58.0173 40.8451 65.004 36.3952 68.2674 29.9125C67.3476 29.6962 66.3837 29.5786 65.3904 29.5786Z"
            fill={darkTurquoise(greyscale)}
            stroke="black"
            strokeWidth="2"
          />
        </>
      );
    case ImpactCategory.WATER_USE:
      return (
        <>
          <path
            d="M50.5305 40.845C61.4906 40.845 70.3755 31.9601 70.3755 21C70.3755 10.0399 61.4906 1.15503 50.5305 1.15503C39.5705 1.15503 30.6855 10.0399 30.6855 21C30.6855 31.9601 39.5705 40.845 50.5305 40.845Z"
            fill={foodstepsTurquoise(greyscale)}
            stroke="black"
            strokeWidth="2"
          />
          <path
            d="M51.6943 19.5952C51.6943 16.4032 55.3672 9.92467 55.3672 9.92467C55.7641 9.06157 57.1207 9.06157 57.5176 9.92467C57.5176 9.92467 61.1905 16.294 61.1905 19.5952C61.1905 22.2181 59.0653 24.6709 56.4424 24.6709C53.8195 24.6709 51.6943 22.2181 51.6943 19.5952Z"
            fill={mint(greyscale)}
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M50.5306 40.8451C58.1515 40.8451 64.7686 36.5485 68.0929 30.2443C61.9525 37.9639 56.5534 27.9448 56.5534 27.9448C49.1719 39.2554 43.4725 27.2476 43.4725 27.2476C40.2763 31.3237 37.2565 32.779 34.6021 32.842C38.2204 37.6993 44.0059 40.8472 50.5285 40.8472L50.5306 40.8451Z"
            fill={darkTurquoise(greyscale)}
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M27.4058 24.3055C27.4058 17.5183 35.2136 3.75068 35.2136 3.75068C36.0557 1.91318 38.9411 1.91318 39.7832 3.75068C39.7832 3.75068 47.591 17.2873 47.591 24.3055C47.591 29.8789 43.0718 35.0932 37.4984 35.0932C31.925 35.0932 27.4058 29.8789 27.4058 24.3055Z"
            fill={mint(greyscale)}
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      );
  }
}

function impactCategoryTitleFontSize(
  impactCategory: ImpactCategory,
  locale: string | null
): number {
  if (locale === "de-DE" && impactCategory === ImpactCategory.WATER_USE) {
    return 10;
  } else if (
    locale === "nl-NL" &&
    (impactCategory === ImpactCategory.LAND_USE ||
      impactCategory === ImpactCategory.WATER_USE)
  ) {
    return 12;
  }
  return 13;
}

function impactRatingFontSize(
  impactRating: ImpactRating,
  locale: string | null
): number {
  if (locale === "de-DE" && impactRating === "VLOW") {
    return 14;
  }
  return 16;
}

interface TitleProps {
  impactCategory: ImpactCategory;
  locale: string | null;
}

function Title(props: TitleProps) {
  const { impactCategory, locale } = props;

  const titleString = impactCategoryTitle(impactCategory, locale);

  const y = 78;

  const commonProps = {
    y,
    x: 49,
    textAnchor: "middle",
    fontFamily: "Graphik Web",
    fontWeight: 500,
    fontSize: impactCategoryTitleFontSize(impactCategory, locale),
    fill: "black",
  };

  if (
    locale === "fr-FR" &&
    (impactCategory === ImpactCategory.LAND_USE ||
      impactCategory === ImpactCategory.WATER_USE)
  ) {
    const words = titleString.split(" ");
    const firstWord = words.shift();
    return (
      <>
        <text {...commonProps}>{firstWord}</text>
        <text {...commonProps} y={y + lineHeight}>
          {words.join(" ")}
        </text>
      </>
    );
  } else {
    return <text {...commonProps}>{titleString}</text>;
  }
}
