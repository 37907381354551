import { ImpactCategory } from "../../../../domain/impactCategories";
import {
  ImpactRating,
  useImpactRatingToColor,
} from "../../../../domain/impactRatings";
import { graphikSemiboldWebCssString } from "../../../../fonts/graphik-web/graphikSemiboldWebCssString";
import {
  darkTurquoise,
  foodstepsTurquoise,
  mint,
} from "../../../graphs/colors";
import { impactRatingLetterColor } from "../../impactRatingLetterColor";
import { impactRatingToLetter } from "./translations";

interface RatingScaleProps {
  greyscale?: boolean;
  impactCategory: ImpactCategory;
  impactRating: ImpactRating;
  width: number;
}

export default function RatingScale(props: RatingScaleProps) {
  const { greyscale = false, impactCategory, impactRating, width } = props;

  return (
    <svg
      width={width}
      viewBox="0 0 108 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <style>{graphikSemiboldWebCssString}</style>
      <ImpactCategoryIllustration
        greyscale={greyscale}
        impactCategory={impactCategory}
      />
      <rect x="31" y="1" width="76" height="40" rx="7" fill="white" />
      <rect
        x="31"
        y="1"
        width="76"
        height="40"
        rx="7"
        stroke="black"
        strokeWidth="2"
      />
      <ImpactRatingScale greyscale={greyscale} impactRating={impactRating} />
      <defs>
        <clipPath id="clip0_148_1613">
          <rect width="46" height="42" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

interface ImpactRatingScaleProps {
  greyscale: boolean;
  impactRating: ImpactRating;
}

function ImpactRatingScale(props: ImpactRatingScaleProps) {
  const { greyscale, impactRating } = props;

  const impactRatingToColor = useImpactRatingToColor(greyscale);

  const ratingLetterColor = impactRatingLetterColor(impactRating, greyscale);
  const ratingLetter = impactRatingToLetter(impactRating);

  const ratingLetterY = 26.7;
  const ratingLetterXA = 49;
  const ratingLetterFontSize = 16;
  const distanceBetweenRatingLetters = 10;

  switch (impactRating) {
    case "VLOW":
      return (
        <>
          <rect
            x="39"
            y="9"
            width="20"
            height="24"
            rx="5"
            fill={impactRatingToColor("VLOW")}
            stroke="black"
            strokeWidth="2"
          />
          <path d="M62 13H70V29H62V13Z" fill={impactRatingToColor("LOW")} />
          <rect
            x="72"
            y="13"
            width="8"
            height="16"
            fill={impactRatingToColor("MEDIUM")}
          />
          <rect
            x="82"
            y="13"
            width="8"
            height="16"
            fill={impactRatingToColor("HIGH")}
          />
          <path
            d="M92 13H96C98.2091 13 100 14.7909 100 17V25C100 27.2091 98.2091 29 96 29H92V13Z"
            fill={impactRatingToColor("VHIGH")}
          />
          <text
            x={ratingLetterXA}
            y={ratingLetterY}
            textAnchor="middle"
            fontFamily="Graphik Web"
            fontWeight={600}
            fontSize={ratingLetterFontSize}
            fill={ratingLetterColor}
          >
            {ratingLetter}
          </text>
        </>
      );
    case "LOW":
      return (
        <>
          <path
            d="M38 17C38 14.7909 39.7909 13 42 13H46V29H42C39.7909 29 38 27.2091 38 25V17Z"
            fill={impactRatingToColor("VLOW")}
          />
          <rect
            x="49"
            y="9"
            width="20"
            height="24"
            rx="5"
            fill={impactRatingToColor("LOW")}
            stroke="black"
            strokeWidth="2"
          />
          <rect
            x="72"
            y="13"
            width="8"
            height="16"
            fill={impactRatingToColor("MEDIUM")}
          />
          <rect
            x="82"
            y="13"
            width="8"
            height="16"
            fill={impactRatingToColor("HIGH")}
          />
          <path
            d="M92 13H96C98.2091 13 100 14.7909 100 17V25C100 27.2091 98.2091 29 96 29H92V13Z"
            fill={impactRatingToColor("VHIGH")}
          />
          <text
            x={ratingLetterXA + distanceBetweenRatingLetters}
            y={ratingLetterY}
            textAnchor="middle"
            fontFamily="Graphik Web"
            fontWeight={600}
            fontSize={ratingLetterFontSize}
            fill={ratingLetterColor}
          >
            {ratingLetter}
          </text>
        </>
      );
    case "MEDIUM":
      return (
        <>
          <path
            d="M38 17C38 14.7909 39.7909 13 42 13H46V29H42C39.7909 29 38 27.2091 38 25V17Z"
            fill={impactRatingToColor("VLOW")}
          />
          <rect
            x="48"
            y="13"
            width="8"
            height="16"
            fill={impactRatingToColor("LOW")}
          />
          <rect
            x="59"
            y="9"
            width="20"
            height="24"
            rx="5"
            fill={impactRatingToColor("MEDIUM")}
            stroke="black"
            strokeWidth="2"
          />
          <rect
            x="82"
            y="13"
            width="8"
            height="16"
            fill={impactRatingToColor("HIGH")}
          />
          <path
            d="M92 13H96C98.2091 13 100 14.7909 100 17V25C100 27.2091 98.2091 29 96 29H92V13Z"
            fill={impactRatingToColor("VHIGH")}
          />
          <text
            x={ratingLetterXA + 2 * distanceBetweenRatingLetters}
            y={ratingLetterY}
            textAnchor="middle"
            fontFamily="Graphik Web"
            fontWeight={600}
            fontSize={ratingLetterFontSize}
            fill={ratingLetterColor}
          >
            {ratingLetter}
          </text>
        </>
      );
    case "HIGH":
      return (
        <>
          <path
            d="M38 17C38 14.7909 39.7909 13 42 13H46V29H42C39.7909 29 38 27.2091 38 25V17Z"
            fill={impactRatingToColor("VLOW")}
          />
          <rect
            x="48"
            y="13"
            width="8"
            height="16"
            fill={impactRatingToColor("LOW")}
          />
          <rect
            x="58"
            y="13"
            width="8"
            height="16"
            fill={impactRatingToColor("MEDIUM")}
          />
          <rect
            x="69"
            y="9"
            width="20"
            height="24"
            rx="5"
            fill={impactRatingToColor("HIGH")}
            stroke="black"
            strokeWidth="2"
          />
          <path
            d="M92 13H96C98.2091 13 100 14.7909 100 17V25C100 27.2091 98.2091 29 96 29H92V13Z"
            fill={impactRatingToColor("VHIGH")}
          />
          <text
            x={ratingLetterXA + 3 * distanceBetweenRatingLetters}
            y={ratingLetterY}
            textAnchor="middle"
            fontFamily="Graphik Web"
            fontWeight={600}
            fontSize={ratingLetterFontSize}
            fill={ratingLetterColor}
          >
            {ratingLetter}
          </text>
        </>
      );
    case "VHIGH":
      return (
        <>
          <path
            d="M38 17C38 14.7909 39.7909 13 42 13H46V29H42C39.7909 29 38 27.2091 38 25V17Z"
            fill={impactRatingToColor("VLOW")}
          />
          <rect
            x="48"
            y="13"
            width="8"
            height="16"
            fill={impactRatingToColor("LOW")}
          />
          <rect
            x="58"
            y="13"
            width="8"
            height="16"
            fill={impactRatingToColor("MEDIUM")}
          />
          <path d="M68 13H76V29H68V13Z" fill={impactRatingToColor("HIGH")} />
          <rect
            x="79"
            y="9"
            width="20"
            height="24"
            rx="5"
            fill={impactRatingToColor("VHIGH")}
            stroke="black"
            strokeWidth="2"
          />
          <text
            x={ratingLetterXA + 4 * distanceBetweenRatingLetters}
            y={ratingLetterY}
            textAnchor="middle"
            fontFamily="Graphik Web"
            fontWeight={600}
            fontSize={ratingLetterFontSize}
            fill={ratingLetterColor}
          >
            {ratingLetter}
          </text>
        </>
      );
  }
}

interface ImpactCategoryIllustrationProps {
  greyscale: boolean;
  impactCategory: ImpactCategory;
}

function ImpactCategoryIllustration(props: ImpactCategoryIllustrationProps) {
  const { greyscale, impactCategory } = props;

  switch (impactCategory) {
    case ImpactCategory.GHG:
      return (
        <>
          <path
            d="M24.2107 40.845C35.1708 40.845 44.0557 31.9601 44.0557 21C44.0557 10.0399 35.1708 1.15503 24.2107 1.15503C13.2506 1.15503 4.36572 10.0399 4.36572 21C4.36572 31.9601 13.2506 40.845 24.2107 40.845Z"
            fill={foodstepsTurquoise(greyscale)}
            stroke="black"
            strokeWidth="2"
          />
          <path
            d="M5.90332 28.6714H42.5189"
            stroke="black"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M19.7085 12.9591C19.7421 11.6571 20.8089 10.6113 22.1193 10.6113C22.2915 10.6113 22.4574 10.6302 22.6191 10.6638C23.0622 8.17532 25.2357 6.28742 27.8502 6.28742C27.8712 6.28742 27.8901 6.28742 27.9111 6.28742C28.5873 4.99592 29.9397 4.11182 31.5 4.11182C33.7365 4.11182 35.5488 5.92412 35.5488 8.16062C35.5488 8.16062 35.5488 8.16272 35.5488 8.16482C35.595 8.16482 35.6412 8.16062 35.6874 8.16062C37.4913 8.16062 38.955 9.62432 38.955 11.4282C38.955 11.9427 38.8353 12.4299 38.6232 12.8625C40.0512 13.0683 41.16 14.3052 41.16 15.7878C41.16 17.4132 39.8286 18.7446 38.2032 18.7446H20.5632C18.9378 18.7446 17.6064 17.4132 17.6064 15.7878C17.6064 14.4585 18.4968 13.3266 19.7085 12.957V12.9591Z"
            fill={mint(greyscale)}
            stroke="black"
            strokeWidth="2"
            strokeLinejoin="round"
          />
          <path
            d="M13.0641 21.313C11.8713 18.8119 13.503 16.8484 13.503 16.8484C9.83013 16.8484 10.08 13.2805 10.08 13.2805C7.29543 15.2125 5.25003 12.9046 5.25003 12.9046C0.193234 13.3372 1.38603 8.79071 1.38603 8.79071C0.249934 3.73391 5.30673 3.79061 5.30673 3.79061C10.3635 -0.526987 13.7739 4.47311 13.7739 4.47311C20.4225 4.30301 18.774 11.1217 18.774 11.1217C21.777 13.3582 18.8937 15.9664 18.8937 15.9664C18.8937 15.9664 21.4893 17.4112 19.3242 21.313"
            fill={mint(greyscale)}
          />
          <path
            d="M13.0641 21.313C11.8713 18.8119 13.503 16.8484 13.503 16.8484C9.83013 16.8484 10.08 13.2805 10.08 13.2805C7.29543 15.2125 5.25003 12.9046 5.25003 12.9046C0.193234 13.3372 1.38603 8.79071 1.38603 8.79071C0.249934 3.73391 5.30673 3.79061 5.30673 3.79061C10.3635 -0.526987 13.7739 4.47311 13.7739 4.47311C20.4225 4.30301 18.774 11.1217 18.774 11.1217C21.777 13.3582 18.8937 15.9664 18.8937 15.9664C18.8937 15.9664 21.4893 17.4112 19.3242 21.313"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M21.8527 22.2159C21.7708 21.6153 21.2584 21.168 20.6536 21.168H12.3628C11.7559 21.168 11.2435 21.6153 11.1637 22.2159L9.52148 34.3413C13.1524 38.3376 18.3898 40.8471 24.2131 40.8471C24.2677 40.8471 24.3223 40.8471 24.379 40.8471L21.8548 22.2201L21.8527 22.2159Z"
            fill={darkTurquoise(greyscale)}
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      );
    case ImpactCategory.LAND_USE:
      return (
        <>
          <path
            d="M24.2107 40.845C35.1708 40.845 44.0557 31.9601 44.0557 21C44.0557 10.0399 35.1708 1.15503 24.2107 1.15503C13.2506 1.15503 4.36572 10.0399 4.36572 21C4.36572 31.9601 13.2506 40.845 24.2107 40.845Z"
            fill={foodstepsTurquoise(greyscale)}
            stroke="black"
            strokeWidth="2"
          />
          <path
            d="M21.5879 1.26831L29.0996 40.238"
            stroke="black"
            strokeWidth="2"
            strokeLinejoin="round"
          />
          <path
            d="M2.79712 24.61H8.18152C8.75482 24.61 9.22102 25.156 9.22102 25.828V30.9121C9.80272 30.7504 9.77962 30.6601 10.5629 30.6601C11.2958 30.6601 14.0426 30.7378 14.5907 30.8806V25.8301C14.5907 25.1581 15.0569 24.6121 15.6302 24.6121H21.0146C22.4993 24.6121 23.2133 22.7893 22.1234 21.7813L17.3291 17.3482H19.1771C20.6618 17.3482 21.3758 15.5254 20.2859 14.5174L15.9935 10.5484H17.1716C18.6563 10.5484 19.3703 8.72559 18.2804 7.71759L13.4042 3.42309C12.5474 2.66919 11.2643 2.66919 10.4096 3.42309L5.53342 7.71759C4.44352 8.72559 5.15542 10.5484 6.64222 10.5484H7.82032L3.52792 14.5174C2.43802 15.5254 3.14992 17.3482 4.63672 17.3482H6.48472L1.69042 21.7813C0.600523 22.7893 1.31242 24.6121 2.79922 24.6121L2.79712 24.61Z"
            fill={mint(greyscale)}
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M24.2907 14.5193H42.9723C40.2864 6.74093 32.9007 1.15283 24.2109 1.15283C23.3478 1.15283 22.4973 1.20743 21.6636 1.31453L24.2907 14.5172V14.5193Z"
            fill={mint(greyscale)}
            stroke="black"
            strokeWidth="2"
          />
          <path
            d="M39.0706 29.5786C36.874 29.5786 34.8244 30.1435 33.0709 31.1158C32.5606 31.3993 31.9222 31.3993 31.4119 31.1158C29.6584 30.1435 27.6088 29.5786 25.4122 29.5786C23.2156 29.5786 21.166 30.1435 19.4125 31.1158C18.9022 31.3993 18.2638 31.3993 17.7535 31.1158C16 30.1435 13.9504 29.5786 11.7538 29.5786C9.994 29.5786 8.3266 29.9419 6.8335 30.5866C10.2145 36.7018 16.7287 40.8451 24.2131 40.8451C31.6975 40.8451 38.6842 36.3952 41.9476 29.9125C41.0278 29.6962 40.0639 29.5786 39.0706 29.5786Z"
            fill={darkTurquoise(greyscale)}
            stroke="black"
            strokeWidth="2"
          />
        </>
      );
    case ImpactCategory.WATER_USE:
      return (
        <>
          <path
            d="M24.2107 40.845C35.1708 40.845 44.0557 31.9601 44.0557 21C44.0557 10.0399 35.1708 1.15503 24.2107 1.15503C13.2506 1.15503 4.36572 10.0399 4.36572 21C4.36572 31.9601 13.2506 40.845 24.2107 40.845Z"
            fill={foodstepsTurquoise(greyscale)}
            stroke="black"
            strokeWidth="2"
          />
          <path
            d="M24.2112 40.8451C31.2903 40.8451 37.5021 37.1386 41.0133 31.561C39.3501 29.9902 38.2728 27.9448 38.2728 27.9448C31.5234 39.6964 25.1919 27.9448 25.1919 27.9448C17.8104 39.2554 12.111 27.2476 12.111 27.2476C10.494 29.3098 8.92108 30.7021 7.41748 31.582C10.9329 37.147 17.1384 40.8451 24.207 40.8451H24.2112Z"
            fill={darkTurquoise(greyscale)}
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M19.1982 18.0391C19.1982 13.9924 23.8539 5.78138 23.8539 5.78138C24.3558 4.68518 26.0778 4.68518 26.5797 5.78138C26.5797 5.78138 31.2354 13.8538 31.2354 18.0391C31.2354 21.3634 28.5411 24.4714 25.2168 24.4714C21.8925 24.4714 19.1982 21.3634 19.1982 18.0391Z"
            fill={mint(greyscale)}
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M1.08594 24.3055C1.08594 17.5183 8.89374 3.75068 8.89374 3.75068C9.73584 1.91318 12.6212 1.91318 13.4633 3.75068C13.4633 3.75068 21.2711 17.2873 21.2711 24.3055C21.2711 29.8789 16.7519 35.0932 11.1785 35.0932C5.60514 35.0932 1.08594 29.8789 1.08594 24.3055Z"
            fill={mint(greyscale)}
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      );
  }
}
