import { ImpactCategory } from "../../../../domain/impactCategories";
import {
  ImpactRating,
  useImpactRatingToColor,
} from "../../../../domain/impactRatings";
import { graphikSemiboldWebCssString } from "../../../../fonts/graphik-web/graphikSemiboldWebCssString";
import {
  darkTurquoise,
  foodstepsTurquoise,
  mint,
} from "../../../graphs/colors";
import { impactRatingLetterColor } from "../../impactRatingLetterColor";
import { impactRatingToLetter } from "./translations";

interface LetterRatingProps {
  greyscale?: boolean;
  impactCategory: ImpactCategory;
  impactRating: ImpactRating;
  width: number;
}

export default function LetterRating(props: LetterRatingProps) {
  const { greyscale = false, impactCategory, impactRating, width } = props;

  const impactRatingToColor = useImpactRatingToColor(greyscale);

  return (
    <svg
      width={width}
      viewBox="0 0 50 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <style>{graphikSemiboldWebCssString}</style>
      <ImpactCategoryIllustration
        greyscale={greyscale}
        impactCategory={impactCategory}
      />
      <rect
        x="25"
        y="1"
        width="24"
        height="30"
        rx="5"
        fill={impactRatingToColor(impactRating)}
        stroke="black"
        strokeWidth="2"
      />
      <text
        x={37}
        y={23}
        textAnchor="middle"
        fontFamily="Graphik Web"
        fontWeight={600}
        fontSize={20}
        fill={impactRatingLetterColor(impactRating, greyscale)}
      >
        {impactRatingToLetter(impactRating)}
      </text>
      <defs>
        <clipPath id="clip0_534_51">
          <rect width="36" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

interface ImpactCategoryIllustrationProps {
  greyscale: boolean;
  impactCategory: ImpactCategory;
}

function ImpactCategoryIllustration(props: ImpactCategoryIllustrationProps) {
  const { greyscale, impactCategory } = props;

  switch (impactCategory) {
    case ImpactCategory.GHG:
      return (
        <>
          <path
            d="M18.4464 31.1199C26.797 31.1199 33.5664 24.3504 33.5664 15.9999C33.5664 7.64934 26.797 0.879883 18.4464 0.879883C10.0959 0.879883 3.32642 7.64934 3.32642 15.9999C3.32642 24.3504 10.0959 31.1199 18.4464 31.1199Z"
            fill={foodstepsTurquoise(greyscale)}
            stroke="black"
            strokeWidth="2"
          />
          <path
            d="M4.49756 21.8447H32.3952"
            stroke="black"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M15.0159 9.87361C15.0415 8.88161 15.8543 8.08481 16.8527 8.08481C16.9839 8.08481 17.1103 8.09921 17.2335 8.12481C17.5711 6.22881 19.2271 4.79041 21.2191 4.79041C21.2351 4.79041 21.2495 4.79041 21.2655 4.79041C21.7807 3.80641 22.8111 3.13281 23.9999 3.13281C25.7039 3.13281 27.0847 4.51361 27.0847 6.21761C27.0847 6.21761 27.0847 6.21921 27.0847 6.22081C27.1199 6.22081 27.1551 6.21761 27.1903 6.21761C28.5647 6.21761 29.6799 7.33281 29.6799 8.70721C29.6799 9.09921 29.5887 9.47041 29.4271 9.80001C30.5151 9.95681 31.3599 10.8992 31.3599 12.0288C31.3599 13.2672 30.3455 14.2816 29.1071 14.2816H15.6671C14.4287 14.2816 13.4143 13.2672 13.4143 12.0288C13.4143 11.016 14.0927 10.1536 15.0159 9.87201V9.87361Z"
            fill={mint(greyscale)}
            stroke="black"
            strokeWidth="2"
            strokeLinejoin="round"
          />
          <path
            d="M9.95351 16.2384C9.04471 14.3328 10.2879 12.8368 10.2879 12.8368C7.48951 12.8368 7.67991 10.1184 7.67991 10.1184C5.55831 11.5904 3.99991 9.83198 3.99991 9.83198C0.14711 10.1616 1.05591 6.69758 1.05591 6.69758C0.19031 2.84478 4.04311 2.88798 4.04311 2.88798C7.89591 -0.401618 10.4943 3.40798 10.4943 3.40798C15.5599 3.27838 14.3039 8.47358 14.3039 8.47358C16.5919 10.1776 14.3951 12.1648 14.3951 12.1648C14.3951 12.1648 16.3727 13.2656 14.7231 16.2384"
            fill={mint(greyscale)}
          />
          <path
            d="M9.95351 16.2384C9.04471 14.3328 10.2879 12.8368 10.2879 12.8368C7.48951 12.8368 7.67991 10.1184 7.67991 10.1184C5.55831 11.5904 3.99991 9.83198 3.99991 9.83198C0.14711 10.1616 1.05591 6.69758 1.05591 6.69758C0.19031 2.84478 4.04311 2.88798 4.04311 2.88798C7.89591 -0.401618 10.4943 3.40798 10.4943 3.40798C15.5599 3.27838 14.3039 8.47358 14.3039 8.47358C16.5919 10.1776 14.3951 12.1648 14.3951 12.1648C14.3951 12.1648 16.3727 13.2656 14.7231 16.2384"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16.6496 16.9263C16.5872 16.4687 16.1968 16.1279 15.736 16.1279H9.41919C8.95679 16.1279 8.56639 16.4687 8.50559 16.9263L7.25439 26.1647C10.0208 29.2095 14.0112 31.1215 18.448 31.1215C18.4896 31.1215 18.5312 31.1215 18.5744 31.1215L16.6512 16.9295L16.6496 16.9263Z"
            fill={darkTurquoise(greyscale)}
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      );
    case ImpactCategory.LAND_USE:
      return (
        <>
          <path
            d="M18.4464 31.1199C26.797 31.1199 33.5664 24.3504 33.5664 15.9999C33.5664 7.64934 26.797 0.879883 18.4464 0.879883C10.0959 0.879883 3.32642 7.64934 3.32642 15.9999C3.32642 24.3504 10.0959 31.1199 18.4464 31.1199Z"
            fill={foodstepsTurquoise(greyscale)}
            stroke="black"
            strokeWidth="2"
          />
          <path
            d="M16.448 0.966309L22.1712 30.6575"
            stroke="black"
            strokeWidth="2"
            strokeLinejoin="round"
          />
          <path
            d="M2.13121 18.7504H6.23361C6.67041 18.7504 7.02561 19.1664 7.02561 19.6784V23.552C7.46881 23.4288 7.45121 23.36 8.04801 23.36C8.60641 23.36 10.6992 23.4192 11.1168 23.528V19.68C11.1168 19.168 11.472 18.752 11.9088 18.752H16.0112C17.1424 18.752 17.6864 17.3632 16.856 16.5952L13.2032 13.2176H14.6112C15.7424 13.2176 16.2864 11.8288 15.456 11.0608L12.1856 8.03685H13.0832C14.2144 8.03685 14.7584 6.64805 13.928 5.88005L10.2128 2.60805C9.56001 2.03365 8.58241 2.03365 7.93121 2.60805L4.21601 5.88005C3.38561 6.64805 3.92801 8.03685 5.06081 8.03685H5.95841L2.68801 11.0608C1.85761 11.8288 2.40001 13.2176 3.53281 13.2176H4.94081L1.28801 16.5952C0.457611 17.3632 1.00001 18.752 2.13281 18.752L2.13121 18.7504Z"
            fill={mint(greyscale)}
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M18.5072 11.0624H32.7408C30.6944 5.13602 25.0672 0.878418 18.4464 0.878418C17.7888 0.878418 17.1408 0.920018 16.5056 1.00162L18.5072 11.0608V11.0624Z"
            fill={mint(greyscale)}
            stroke="black"
            strokeWidth="2"
          />
          <path
            d="M29.7679 22.5359C28.0943 22.5359 26.5327 22.9663 25.1967 23.7071C24.8079 23.9231 24.3215 23.9231 23.9327 23.7071C22.5967 22.9663 21.0351 22.5359 19.3615 22.5359C17.6879 22.5359 16.1263 22.9663 14.7903 23.7071C14.4015 23.9231 13.9151 23.9231 13.5263 23.7071C12.1903 22.9663 10.6287 22.5359 8.9551 22.5359C7.6143 22.5359 6.3439 22.8127 5.2063 23.3039C7.7823 27.9631 12.7455 31.1199 18.4479 31.1199C24.1503 31.1199 29.4735 27.7295 31.9599 22.7903C31.2591 22.6255 30.5247 22.5359 29.7679 22.5359Z"
            fill={darkTurquoise(greyscale)}
            stroke="black"
            strokeWidth="2"
          />
        </>
      );
    case ImpactCategory.WATER_USE:
      return (
        <>
          <path
            d="M18.4464 31.1199C26.797 31.1199 33.5664 24.3504 33.5664 15.9999C33.5664 7.64934 26.797 0.879883 18.4464 0.879883C10.0959 0.879883 3.32642 7.64934 3.32642 15.9999C3.32642 24.3504 10.0959 31.1199 18.4464 31.1199Z"
            fill={foodstepsTurquoise(greyscale)}
            stroke="black"
            strokeWidth="2"
          />
          <path
            d="M18.4463 31.12C23.8399 31.12 28.5727 28.296 31.2479 24.0464C29.9807 22.8496 29.1599 21.2912 29.1599 21.2912C24.0175 30.2448 19.1935 21.2912 19.1935 21.2912C13.5695 29.9088 9.22712 20.76 9.22712 20.76C7.99512 22.3312 6.79672 23.392 5.65112 24.0624C8.32952 28.3024 13.0575 31.12 18.4431 31.12H18.4463Z"
            fill={darkTurquoise(greyscale)}
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M14.6272 13.7439C14.6272 10.6607 18.1744 4.40472 18.1744 4.40472C18.5568 3.56952 19.8688 3.56952 20.2512 4.40472C20.2512 4.40472 23.7984 10.5551 23.7984 13.7439C23.7984 16.2767 21.7456 18.6447 19.2128 18.6447C16.68 18.6447 14.6272 16.2767 14.6272 13.7439Z"
            fill={mint(greyscale)}
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M0.827148 18.5184C0.827148 13.3472 6.77595 2.85762 6.77595 2.85762C7.41755 1.45762 9.61595 1.45762 10.2575 2.85762C10.2575 2.85762 16.2064 13.1712 16.2064 18.5184C16.2064 22.7648 12.7631 26.7376 8.51675 26.7376C4.27035 26.7376 0.827148 22.7648 0.827148 18.5184Z"
            fill={mint(greyscale)}
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      );
  }
}
